"use strict";
import axios from "axios";
import { Notify } from '@nutui/nutui';
import router from '@/router/index';
import store from '@/store/index'
let _axios = axios.create({
  baseURL: process.env.VUE_APP_API+'/api',
  timeout: 30000,
  withCredentials: true,
  headers: {
    'Accept': 'application/json'
  }
});

_axios.interceptors.request.use(
  function(config) {
    // Do something before request is sent
    let token = localStorage.getItem('token');
    config.headers['Authorization'] = token;
    return config;
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
_axios.interceptors.response.use(
  function(response) {
    // Do something with response data
    if (response.data.code === 0) {
      return response.data;
    } else if(response.data.code === 1) {
      Notify.danger(response.data.msg);
      return Promise.reject(response.data);
    } else if (response.data.code === 2) {
      if (window.location.pathname == '/login') {
        return Promise.reject(response.data);
      }
      localStorage.setItem('token', '');
      store.commit('setUserInfo', null);
      router.push({
        path: '/login'
      });
      return Promise.reject(response.data);
    }
    return response;
  },
  function(error) {
    // Do something with response error
    return Promise.reject(error);
  }
);

export default _axios;
