import './plugins/axios'
import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import NutUI from "@nutui/nutui";
import "./assets/font/iconfont.css"
import "@nutui/nutui/dist/styles/themes/default.scss";
import './assets/scss/index.scss';
import VueClipboard from 'vue-clipboard2';
import { InfiniteLoading } from '@nutui/nutui';
import i18n from "@/i18n";
createApp(App).use(router).use(InfiniteLoading).use(NutUI).use(i18n).use(VueClipboard).use(store).mount('#app');
