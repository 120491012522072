import _axios from '@/plugins/axios'

// 配置表
export function initData() {
  return _axios({
    url: '/base/initData',
    method: 'post'
  })
}


// 首页数据
export function homeData() {
  return _axios({
    url: '/home/data',
    method: 'post'
  })
}