import _axios from '@/plugins/axios'

// 登录
export function login({country_code,account, password}) {
  return _axios({
    url: '/login/index',
    method: 'post',
    data: {
      country_code,
      account,
      password,
    }
  })
}

// 注册
export function register({country_code,account, password, invite_code, nickname}) {
  return _axios({
    url: '/register/index',
    method: 'post',
    data: {
      country_code, // 国家
      account, // 账户
      password, // 密码
      invite_code, // 邀请码
      nickname // 昵称
    }
  })
}

// 获取用户信息
export function userInfo() {
  return _axios({
    url: '/user/info',
    method: 'post'
  })
}

// 修改登录密码
export function updatePwd(password_old, password_new) {
  return _axios({
    url: '/user/updatePwd',
    method: 'post',
    data: {
      password_old, // 登录密码
      password_new // 交易密码
    }
  })
}

// 设置资金密码-6位纯数字
export function setPayPwd(password_login, password_pay) {
  return _axios({
    url: '/user/setPayPwd',
    method: 'post',
    data: {
      password_login, // 登录密码
      password_pay // 交易密码
    }
  })
}

// 更新资金密码-6位纯数字
export function PayPwd(password_old, password_new) {
  return _axios({
    url: '/user/updatePayPwd',
    method: 'post',
    data: {
      password_old,
      password_new
    }
  })
}

// 更新昵称
export function updateNickname(nickname) {
  return _axios({
    url: '/user/updateNickname',
    method: 'post',
    data: {
      nickname
    }
  })
}

// 更新提款地址
export function updateWithdrawAddr(addr) {
  return _axios({
    url: '/user/updateWithdrawAddr',
    method: 'post',
    data: {
      addr
    }
  })
}

// 充值信息
export function recharge() {
  return _axios({
    url: '/recharge/index',
    method: 'post'
  })
}


// 充值记录
export function rechargeList() {
  return _axios({
    url: '/recharge/list',
    method: 'post'
  })
}

// 提现
export function withdraw(amount,password) {
  return _axios({
    url: '/withdraw/index',
    method: 'post',
    data: {
      amount,
      password
    }
  })
}

// 提现记录
export function withdrawList() {
  return _axios({
    url: '/withdraw/list',
    method: 'post'
  })
}

// 资金明细
export function assetLog() {
  return _axios({
    url: '/asset/log',
    method: 'post'
  })
}

// 抢单页数据统计
export function orderStat() {
  return _axios({
    url: '/order/stat',
    method: 'post'
  })
}


// 抢单提交
export function orderGrab() {
  return _axios({
    url: '/order/grab',
    method: 'post'
  })
}

// 订单记录
export function orderList(status) {
  return _axios({
    url: '/order/list',
    method: 'post',
    data: {
      status // 状态paid已完成 unpaid未完成
    }
  })
}

// 支付订单
export function orderPay(order_id, password) {
  return _axios({
    url: '/order/pay',
    method: 'post',
    data: {
      order_id,
      password
    }
  })
}