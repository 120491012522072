
import {initData} from "@/api/config";

const state = {
  configInfo: '',
  currentLang:  {
    text: 'English',
    value: 'en'
  },
};
const mutations = {
  setConfig(state, data) {
    state.configInfo = data;
  },
  setLang(state) {
    let langObj = localStorage.getItem('lang');
    if (langObj) {
      state.currentLang = JSON.parse(langObj);
    }
  }
};
const actions = {

  getConfig({commit}) {
    // 获取提款配置信息
    initData().then((res)=>{
      commit('setConfig', res.data);
    });
  }
};


export default  {
  state,
  actions,
  mutations
}
