import { createRouter, createWebHistory } from 'vue-router'
import store from '../store';
const routes = [
  {
    path: '/',
    name: 'main', // 入口
    component: () => import('../views/main'),
    children: [
      {
        path: '', //  首页
        name: 'home',
        component: () => import('../views/main/home')
      },
      {
        path: 'order', //  订单列表
        name: 'order',
        component: () => import('../views/main/order')
      },
      {
        path: 'order', //  订单列表
        name: 'order',
        component: () => import('../views/main/order')
      },
      {
        path: 'take', //  接单
        name: 'take',
        component: () => import('../views/main/take')
      },
      {
        path: 'team', //  团队
        name: 'team',
        component: () => import('../views/main/team')
      },
      {
        path: 'team/detail', //  团队明细
        name: 'team/detail',
        component: () => import('../views/main/team/detail')
      },
      {
        path: 'my', // 我的
        name: 'my',
        component: () => import('../views/main/my')
      },
      {
        path: 'my/recharge', // 充值
        name: 'recharge',
        component: () => import('../views/main/my/recharge')
      },
      {
        path: 'my/center', // 个人信息
        name: 'center',
        component: () => import('../views/main/my/center')
      },
      {
        path: 'my/invite', // 邀请
        name: 'invite',
        component: () => import('../views/main/my/invite')
      },
      {
        path: 'my/withdraw', // 提现
        name: 'withdraw',
        component: () => import('../views/main/my/withdraw')
      },
      {
        path: 'my/fund/record', // 资金明细
        name: 'fund-record',
        component: () => import('../views/main/my/fund-record')
      },
      {
        path: 'my/recharge/record', // 充值
        name: 'recharge-record',
        component: () => import('../views/main/my/recharge-record')
      },
      {
        path: 'my/withdraw/record', // 提现
        name: 'withdraw-record',
        component: () => import('../views/main/my/withdraw-record')
      },
    ]
  },
  {
    path: '/login', // 登陆
    name: 'login',
    component: () => import('../views/login'),
  },
  {
    path: '/register', // 注册
    name: 'register',
    component: () => import('../views/register'),
  },
  {
    path: '/language', // 设置语言
    name: 'language',
    component: () => import('../views/language'),
  },
  {
    path: '/help',
    name: 'help', // 帮助
    component: () => import('../views/help/index')
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  }
});

// 需要登陆的界面
let avoidLanding = ['login', 'language', 'register', 'home', 'help']

router.beforeEach((to, from, next)=>{
  let token = localStorage.getItem('token');
  if (token) {
    next();
  } else {
    if (avoidLanding.indexOf(to.name) != -1) {
      next();
    } else {
      next({
        name: 'login'
      })
    }
  }
});



export default router
