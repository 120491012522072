import {userInfo} from "@/api/user";
const state = {
  userInfo: ''
};
const mutations = {
  setUserInfo(state, info) {
    state.userInfo = info;
  },
};
const actions = {
   getUserInfo({commit}) {
     // 获取用户信息
     return new Promise((resolve, reject)=>{
       userInfo().then((res)=>{
         commit('setUserInfo', res.data);
         resolve();
       }).catch(()=>{
         reject();
       })
     })
   }
};


export default  {
  state,
  actions,
  mutations
}
