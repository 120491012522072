<template>
  <router-view/>
</template>
<script>
 export default {
   created() {
     this.$store.commit('setLang');
      this.getInviteCode("code");
     let token = localStorage.getItem('token');
     this.$store.dispatch('getConfig');
     if (token) {
       this.$store.dispatch('getUserInfo');
     }
   },
   methods: {
     getInviteCode(code) {
       // 获取邀请码
       let reg = new RegExp("(^|&)" + code + "=([^&]*)(&|$)");
       let r = window.location.search.substr(1).match(reg);
       if (r!== null) {
         localStorage.setItem("code", r[2]);
       }
     }
   }
 }
</script>
